/* additional js here */
var template_path = $('html').data('path');
var child_path = template_path.replace('webwerk', 'webwerk-child');

// Frontend
$(document).ready(function () {
    $('.breadcrumb-nav__item').each(function () {
        var originalText = $(this).children('span').text();

        if (originalText.indexOf('WW-') !== -1) {
            var newText = originalText.replace('WW-', '');
            $(this).children('span').text(newText);
        }
    });

    $('a[href="/ww-events"]').removeAttr('target').removeAttr('title');
});

// Interner Bereich
$(document).ready(function () {

    // Fokus auf Eingabefeld nach Login
    $('#user_login').focus();

    // Erstes Accordion automatisch geöffnet
    let path = $('.main--intern .accordion:first-of-type .accordion-item__content');
    // console.log(path);
    path.css('display', 'block');
});

// WP_COLUMN Border
$(document).ready(function () {
    $('.wp-block-column').has('.video').addClass('has-video');
});